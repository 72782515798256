/* Main Container Grid  */
.main-container-grid {
  margin-top: 14px;
  max-width: 1080px;
}

/* Top Section  */
.section-grid {
  padding: 28px;
}

.bc-paper {
  padding: 28px 28px 16px;
  margin: 18px 10px;
}

.paper-title-grid {
  margin-bottom: 16px;
}

.prompt-type-divider {
  width: 100%;
  height: 1px;
  background-color: var(--chakra-colors-lightMode-greys-800);
  margin: 40px 0;
}

/* Bottom Prompts Section  */

/* Default prompt  */

.select-default-prompt {
  padding: 20px 24px;
  min-width: 350px;
  background-color: #0a2740;
  color: #e5e5e5;
  cursor: pointer;
  animation: glow 8s ease-in infinite;
  border-radius: 5px;
  border: 2px solid #5c5da6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 500px;
  min-height: 93px;
}

.delete-prompt-grid {
  margin: 4px;
}

@keyframes glow {
  0% {
    background-color: #0a2740;
  }
  40% {
    background-color: #5c5da6;
  }
  75% {
    background-color: #5c5da6;
  }
  100% {
    background-color: #0a2740;
  }
}

/* Default Prompt Dialog  */
.default-items-grid {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 400px;
}

.default-prompt-title {
  color: #0a2740;
  font-size: 14px;
  font-weight: 500;
  font-family: Open Sans, sans-serif;
}

.default-prompt-title-type {
  color: #0a2740;
  font-size: 13px;
  font-weight: 500;
  font-family: Open Sans, sans-serif;
  font-style: italic;
  text-transform: capitalize;
}

.default-prompt-title-url {
  color: #0a2740;
  font-size: 13px;
  font-weight: 500;
  font-family: Open Sans, sans-serif;
  font-style: italic;
}

.default-prompt-dialog {
  padding: 10px;
}

.no-other-prompts {
  color: #e5e5e5;
  border: 2px solid #cc4e00;
  padding: 10px;
  cursor: pointer;
}

.change-default-prompt-select {
  color: #0a2740;
  overflow-y: hidden;
  min-height: 70px;
  text-align: left;
}

.change-default-prompt-select-divider {
  height: 2px;
  background-color: #e5e5e5;
  margin: 2px 0;
  width: 100%;
}

.prompt-title {
  max-width: 400px;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
