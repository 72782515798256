.pricing-wrapper ul {
  list-style: none;
}

.pricing-list {
  margin: 2em 0 0;
  padding: 0;
}

.pricing-wrapper {
  position: relative;
  text-align: center;
  padding: 0px;
  border: 1px solid #eee;
  background: #fff;
  font-family: Roboto, sans-serif;
  min-width: 300px;
}

.pricing-wrapper > li {
  background-color: #fff;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  outline: 1px solid transparent;
}

.pricing-wrapper-grid {
  display: flex;
  padding: 0 5px 5px;
}

.pricing-features .pricing-get-started-button {
  margin-top: 14px;
}

.annual-switch-grid {
  padding: 10px 0 15px;
}

.pricing-wrapper-metered > li {
  background-color: #fff;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  outline: 1px solid transparent;
}

.price {
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 20px;
  font-size: 2rem;
  font-weight: 600;
}

.exclusive .price {
  background-color: #5c5fa4;
  padding: 5px 0;
}

.pricing-header-title {
  color: #0a2740;
  width: 100%;
  font-size: 2em;
  font-weight: 600;
  padding: 1em 1em 0;
  text-align: center;
}
.pricing-header {
  height: auto;
  font-size: 1em;
  pointer-events: auto;
  text-align: center;
  background: #fff;
}

.pricing-title {
  font-size: 1.5rem;
  font-weight: 600;
  padding: 16px;
  text-transform: none;
  text-align: center;
  color: #0e193a;
  background-color: #fff;
  align-items: end;
  font-weight: 100;
}

.pricing-title-large {
  font-size: 50px;
}

.pricing-pill {
  background: #fff6f2;
  color: #231935;
  border-radius: 20px;
  font-weight: bold;
  display: inline-block;
  padding: 10px 20px;
  font-size: 14px;
}

.pricing-body {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  overflow-x: visible;
  border-top: none;
  background: #fff;
}

.is-switched .pricing-body {
  overflow: hidden;
}

.pricing-features {
  padding: 6px;
  color: #0a2740;
  width: auto;
  list-style-type: none;
}

.pricing-features:after {
  content: '';
  display: table;
  clear: both;
}

.pricing-features li {
  font-size: 1rem;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #0a2740;
  display: flex;
  align-items: center;
  float: none;
  width: auto;
  padding: 0.6em;
}

.pricing-features em {
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
}

.pricing-features .pricing-check-icon {
  color: var(--chakra-colors-bcGreen);
  margin-right: 30px;
}

.exclusive .pricing-features li {
  margin: 0 3px;
}

.pricing-features em {
  display: inline-block;
  margin-bottom: 0;
}

.has-margins .exclusive .pricing-features li {
  margin: 0;
}

.pricing-features-engagements {
  font-weight: bold;
  border-top: none !important;
}

.price-grid {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.value {
  font-size: 2rem;
}

.duration {
  font-size: 0.875rem;
  font-weight: 400;
  color: #999;
}

.exclusive .select {
  background-color: #5c5fa4;
  color: #ffffff;
}

.has-margins .select {
  display: block;
  /* padding: 1.7em 0; */
  border-radius: 0 0 4px 4px;
}

.svg-wrapper {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  width: 320px;
}
.shape {
  stroke-dasharray: 140 540;
  stroke-dashoffset: -474;
  stroke-width: 10px;
  fill: #5c5fa4;

  stroke: #7ed2eb;
  /* border-bottom: 5px solid black; */
  transition:
    stroke-width 1s,
    stroke-dashoffset 1s,
    stroke-dasharray 1s;
}
.text {
  font-family:
    Open Sans,
    sans-serif;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 3px;
  color: #fff;
  top: -48px;
  position: relative;
  overflow: hidden;
  text-align: center;
}
.svg-wrapper:hover .shape {
  stroke-width: 5px;
  stroke-dashoffset: 0;
  stroke-dasharray: 760;
}

.freePlanBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  bottom: 0;
  min-height: 100px;
  background-image: linear-gradient(to right, #5c5da6, #0a2740);
  padding: 18px 100px;
  width: 100%;
  z-index: 10;
}

.freePlanBarHeader {
  font-size: 20px;
  color: #f0f3f6;
}

.freePlanBarDetails {
  font-size: 14px;
  color: #f0f3f6;
}

/* Dialog  */

.update-payment-dialog-title {
  margin: 10px 16px 0;
}

/* Roles Paper  */

.roles-email {
  margin-right: 20px;
  width: 300px;
  max-width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.roles-role {
  margin-right: 20px;
}

.roles-pending-invitation-expired {
  color: red;
}

/* Budget  */

.budget-cap-grid {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  padding: 20px;
}

.budget-event-cap {
  font-size: 1.25em;
  font-weight: 600;
}

.budget-buttons-grid {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 5px;
}
