.main-header {
  font-size: 2em;
  color: #fff;
  margin-bottom: 25px;
  font-family: Roboto, sans-serif;
  text-decoration: underline;
}

.content-header {
  color: #fff;
  font-size: 1.5em;
  font-family: Open Sans, sans-serif;
  display: flex;
}

.content-text {
  color: #fff;
  font-size: 1em;
  font-family: Open Sans, sans-serif;
  margin: 5px 0 30px;
}

.content-text-last {
  color: #fff;
  font-size: 1em;
  font-family: Open Sans, sans-serif;
  margin-bottom: 10px;
}

.content-link {
  cursor: pointer;
}
