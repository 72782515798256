.field-grid {
  display: flex;
  flex-direction: column;
}

.welcome-to-botcopy-grid {
  background-color: #0a2740;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.welcome-to-botcopy-grid-no-dialog-title {
  background-color: #0a2740;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
}

.separator-div {
  width: 50%;
}

.check-circle-grid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
}

/* ONBOARDING FORM INTRO  */

.onboarding-form-intro-grid {
  padding: 80px;
  background-color: #fff;
}

.onboarding-form-intro-welcome-text {
  font-family: Open Sans, sans-serif;
  font-size: 14px;
  text-align: center;
  color: #0a2740;
}

.onboarding-form-intro-button {
  cursor: pointer;
  padding: 10px 60px;
  color: #f0f3f6;
  background-color: #0a2740;
  border: 2px solid #0a2740;
  margin-top: 26px;
  display: flex;
  align-items: center;
  border-radius: 4px;
}

/* ONBOARDING FORM HEADER  */
.onboarding-form-header {
  font-size: 14px;
  margin-bottom: 20px;
  color: #f0f3f6;
  background-color: #0a2740;
  padding: 20px 30px 12px;
  box-shadow: 0 1px 3px 0 #0a2740;
}

.onboarding-dialog-title {
  color: #f0f3f6;
}
/* FORM  */
.onboarding-form-grid {
  overflow-y: scroll;
  max-height: 600px;
  max-width: 550px;
  background-color: #fff;
}

.onboarding-form-title {
  color: #0a2740;
  font-size: 14px;
  font-weight: bold;
  font-family: Open Sans, sans-serif;
  margin: 10px;
}

.onboarding-form-title-need-help {
  color: #0a2740;
  font-size: 14px;
  font-weight: bold;
  font-family: Open Sans, sans-serif;
  margin: 20px 10px 0;
}

.onboarding-form-need-help {
  width: 340px;
  margin: 0 10px;
}

.onboarding-form-email-policy {
  font-size: 13px;
  font-family: Open Sans, sans-serif;
  cursor: pointer;
  max-width: fit-content;
  font-style: italic;
}

.onboarding-form-reasons {
  color: #0a2740;
  font-size: 14px;
  font-weight: bold;
  font-family: Open Sans, sans-serif;
  padding: 10px 0 6px;
  width: 340px;
}

.onboarding-form-reasons-subtitle {
  font-size: 13px;
  font-family: Open Sans, sans-serif;
  margin-bottom: 6px;
  color: #0000008a;
}

.onboarding-actions-grid {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  background-color: #f0f3f6;
}

.onboarding-into-img {
  margin: 5px 0;
}

.onboarding-form-submitted-icon {
  margin-left: 5px;
}

/* BOARD  */

.onboarding-board-grid {
  padding: 30px 20px 20px;
}

.onboarding-board-title {
  color: #0a2740;
  font-family: Open Sans, sans-serif;
  font-size: 24px;
  margin-bottom: 5px;
}

.onboarding-board-details-grid {
  margin-top: 20px;
}

.onboarding-board-details {
  font-size: 14px;
  color: #0a2740;
  text-align: center;
  padding: 6px;
}

.our-policy {
  color: #0a2740;
  text-align: center;
  margin-top: 8px;
  font-size: 13px;
  padding: 10px 0 5px;
}

.right-panel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 25px;
  margin: 0 20px;
  border-radius: 10px;
}

.billing-container {
  display: flex;
  flex-direction: column;
  font-size: 1em;
  font-weight: 600;
  color: #0a2740;
}
