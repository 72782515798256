/* Card Papers */
.card-paper {
  min-width: 300px;
  width: 550px;
  margin: 20px 10px 0;
}

/* Grid within paper */
.card-paper-grid {
  padding: 20px 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card-title {
  padding-bottom: 10px;
  font-size: 1.5em;
  font-weight: 550;
  color: rgb(10, 39, 64);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.card-subtitle {
  font-size: 0.85em;
  padding-bottom: 10px;
  color: grey;
  text-align: center;
}

.engagements-grid {
  margin: 20px 10px 0;
  padding: 20px;
}

.total-engagements-paper {
  margin: 20px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 320px;
}

.engagements-paper {
  margin: 20px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.engagements-paper-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.engagements-title-graph-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.engagements-breakdown-pie {
  width: 250px;
}

.visits-circle {
  border-radius: 50%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.circle-title {
  font-size: 1em;
  padding: 10px;
  color: #0a2740;
  font-weight: 500;
}

.circle-number {
  font-size: 1.5em;
  color: rgb(10, 39, 64);
  font-weight: 550;
}

.main-line-graph-paper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 0 0;
  width: 100%;
  padding: 20px;
}

.main-line-graph-calendar-wrapper {
  position: absolute;
  top: 20px;
  right: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.main-line-graph-calendar-input {
  width: 100px;
  border-radius: 15px;
  margin-right: 5px;
  text-align: center;
  border: 2px solid #e6e8eb;
  cursor: default;
  font-size: 13px;
}

.main-line-graph-calendar {
  position: absolute;
  top: 60px;
  right: 10px;
}

.main-line-graph-view-container {
  position: absolute;
  top: 20px;
  left: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.main-line-graph-view-container > button {
  margin-right: 10px;
}

.main-line-graph-view-button-active {
  background-color: #0a2740;
  color: white;
  padding: 8px 20px;
  border-radius: 4px;
}

.main-line-graph-view-button-inactive {
  cursor: pointer;
  padding: 8px 20px;
}

.main-grid {
  max-width: 1100px;
}

/* Filter  */
.filter-panel {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  width: 100%;
  margin: 0;
}

.filter-bots-tile {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* width: 200px; */
  cursor: pointer;
  opacity: 1;
  padding: 7px 12px;
  background-color: #e6e8eb;
  border-radius: 15px;
  margin: 3px;
}

.filter-bots-tile-grey {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* width: 200px; */
  cursor: pointer;
  opacity: 0.25;
  padding: 7px 12px;
  background-color: #e6e8eb;
  border-radius: 15px;
}

.filter-bots {
  display: 'flex';
  flex-direction: 'row';
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.historical-data-card-paper {
  /* flex: 0 0 calc(30% - 10px); */
  display: flex;
  flex-direction: row;
  width: auto;
  margin: 20px 0 0 10px;
}

.historical-data-container {
  display: flex;
  flex-direction: row;
  width: auto;
}

/*
HEATMAP COMPONENT
*/

.heatmap-card-paper {
  /* flex: 0 0 calc(70% - 20px); */
  flex: 1;
  margin: 20px 10px 0 0;
}

.heatmap-container {
  width: 100%;
  padding-top: 10px;
}

.heatmap-column-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.daily-data-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 12.5%;
}

.daily-data-header {
  font-weight: 550;
}

.daily-data-cell {
  width: 30px;
  height: 20px;
  border: 1px solid grey;
  margin: 3px;
}

.daily-data-bg-activity {
  background-color: rgb(10, 39, 64);
  height: 100%;
  width: 100%;
  text-align: center;
}

.daily-data-bg-no-activity {
  background-color: white;
  height: 100%;
  width: 100%;
  text-align: center;
}

.heatmap-data-axis-wrapper {
  display: flex;
  flex-direction: column;
}

.heatmap-data-column-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
}

.heatmap-y-axis {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  padding: 0 2px;
}

.heatmap-y-axis-unit {
  height: 20px;
  margin: 3px 0 3px 0;
  color: black;
}

.heatmap-x-axis {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.heatmap-x-axis-unit {
  width: 75%;
  height: 25px;
  margin: 3px 0 3px 0;
  color: black;
  text-align: center;
  font-family: Roboto, sans-serif;
  font-size: 12px;
}
