.svg-wrapper {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  width: 320px;
}

.svg-wrapper-start {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  /*width: 320px;*/
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: flex-start;
}

.shape {
  stroke-dasharray: 140 540;
  stroke-dashoffset: -474;
  stroke-width: 10px;
  fill: #5c5da6;

  stroke: #7ed2eb;
  border-bottom: 5px solid black;
  transition: stroke-width 1s, stroke-dashoffset 1s, stroke-dasharray 1s;
}
.text {
  font-family: Open Sans, sans-serif;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 3px;
  color: #fff;
  position: relative;
  overflow: hidden;
  text-align: center;
}
.svg-wrapper:hover .shape {
  stroke-width: 5px;
  stroke-dashoffset: 0;
  stroke-dasharray: 760;
}

.svg-wrapper-start:hover .shape {
  stroke-width: 5px;
  stroke-dashoffset: 0;
  stroke-dasharray: 760;
}

/* Select Platform Dialog  */

.select-platform-dialog {
  padding: 10px;
  min-width: 340px;
}

.select-platform-dialog-title {
  margin: 0 10px;
}

.select-platform-dialog-actions {
  padding-right: 10px;
}

.data-region-label {
  color: #0a2740;
}

.platform-grid {
  padding: 5px 0;
}

.platform-paper-coming-soon {
  font-size: 16px;
  color: #0a2740;
  margin: 0 0 0 10px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.platform-paper-coming-soon:hover .request-access {
  text-decoration: underline #5c5da6;
}

.request-access {
  color: #6c7d8c;
  margin-left: 5px;
  font-size: 14px;
}

.platform-paper {
  margin: 0 10px 0 0;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.platform-paper:hover {
  box-shadow: 0 2px 6px #4b60cc;
}

.platform-type-title {
  font-size: 16px;
  color: #0a2740;
  margin-left: 5px;
  padding-right: 10px;
}

.platform-type-img {
  height: 70px;
  width: 70px;
}

.platform-location-grid {
  display: flex;
  align-items: center;
  position: absolute;
  top: 5px;
  left: 10px;
  padding: 5px 0;
}

.platform-img {
  height: 20px;
  width: 20px;
  margin-right: 6px;
}

.platform-type {
  color: #848482;
  font-size: 13px;
  font-family: Open Sans, sans-serif;
}

.bot-icon-name-grid {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2em;
}

.bot-greeter-icon {
  padding: 5px;
}

.bot-active {
  font-size: 13px;
  color: #0fa50d;
  font-weight: bold;
  font-family: Open Sans, sans-serif;
}

.bot-inactive {
  font-size: 13px;
  color: #a53d0d;
  font-weight: bold;
  font-family: Open Sans, sans-serif;
}
