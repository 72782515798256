.logoAnimation {
  -webkit-animation-name: logoFadeIn;
  animation-name: logoFadeIn;
  -webkit-animation-duration: 2200ms;
  animation-duration: 2200ms;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: drop-down;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  display: flex;
  justify-content: center;
}

@keyframes logoFadeIn {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.25;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
}

.bannerGrid {
  animation: fadeIt 12s ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  height: 95vh;
}

@keyframes fadeIt {
  0% {
    background-color: #0a2740;
  }
  25% {
    background-color: #0a2740;
  }
  75% {
    background-color: #5c5da6;
  }
  100% {
    background-color: #003153;
  }
}

@-webkit-keyframes fadeIt {
  0% {
    background-color: #0a2740;
  }
  25% {
    background-color: #0a2740;
  }
  75% {
    background-color: #5c5da6;
  }
  100% {
    background-color: #003153;
  }
}

.button-primary {
  padding: 0.5rem 1.25rem;
  border-radius: 4px;
  background-color: #4b60cc;
  color: #fff;
  border: none;
}

.banner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2em 3em 1.25em;
  margin: 2em;
  background-color: #fff;
  max-width: 700px;
  border-radius: 8px;
}

.banner-text {
  text-align: center;
  margin-bottom: 1.5em;
}

.banner-title {
  text-align: center;
  font-size: 28px;
  font-weight: 500;
  color: #0a2740;
  padding: 5px;
  font-family: Roboto, sans-serif;
}

.banner-subtitle {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  color: #0a2740;
  padding: 10px 10px 20px;
  text-align: center;
}

.banner-warning-text {
  text-align: center;
  font-size: 14px;
  color: #cc4e00;
  margin-top: 20px;
}

.trusted-by-text {
  color: #f0f3f6;
  padding: 10px;
  margin-top: 10px;
}

.MuiGrid-container-89 > button > div {
  padding: 12px 12px 7px 12px !important;
  margin-right: 10px !important;
}

.google-button {
  background-color: #4285f4 !important;
  color: #fff !important;
  border-radius: 4px !important;
}

.google-button div {
  line-height: 0 !important;
  padding: 14px !important;
}

@media only screen and (min-width: 475px) {
  .google-button span {
    padding: 12px 36px 12px 26px !important;
  }
}

.account-already {
  padding-top: 35px;
  font-family: Roboto, sans-serif;
}

/* .account-already-text {
} */

.login-redirect {
  font-size: 1em;
}

.whiteBoard {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 100px;
}

.whiteBoardItems {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  /* flex-direction: row !important; */
  margin-top: 200px !important;
  /* min-height: 340px !important; */
}

@media only screen and (max-width: 600px) {
  .whiteBoard {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start !important;
    width: 100%;
    padding: 15px;
    margin: 50px 0px 30px 0px;
    height: 100%;
    max-height: 350px;
  }

  .whiteBoardItems {
    padding: 15px;
    width: auto;
    display: flex;
    justify-content: center !important;
    align-items: flex-start !important;
    flex-wrap: wrap !important;
    flex-direction: row !important;
    margin: 50px 0 0 0 !important;
  }

  .whiteBoardItems > div {
    align-items: flex-start !important;
  }

  /* .MuiGrid-container-89 > button > div {
    padding: 12px 12px 7px 12px !important;
    margin-right: 10px !important;
  }

  .button {
    background-color: #4285f4 !important;
    color: #fff !important;
    width: 190px !important;
  } */

  .goodCompany {
    max-width: auto !important;
    width: auto !important;
    text-align: center;
  }

  .mobileLogos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center !important;
    align-items: center !important;
    flex: 1;
    width: 100% !important;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 975px) {
  .indentDiv {
    display: none;
    width: 0px !important;
  }
}
