.alpha {
  height: 100%;
  width: 16px;
  border-radius: 50px;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.saturation {
  position: relative;
  background: rgb(255, 0, 0);
  height: 166px;
  min-width: 150px;
  width: 290px;
  overflow: hidden;
}

.hue {
  height: 100%;
  width: 16px;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.color-input {
  height: 38px;
  padding: 10px;
  border-radius: 4px;
  background-color: rgb(200, 200, 200);
  box-sizing: border-box;
  border: none;
  color: black;
  font-size: 14px;
  caret-color: red;
}

.checkboard-tile {
  height: 57px;
  width: 57px;
  border: 1px solid #ccc;
  border-radius: 8px;
  cursor: pointer;
}

.pointer {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  transform: translate(-9px, -1px);
  background-color: rgb(248, 248, 248);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.37);
}

.slider {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
  border: 2px solid #fff;
}

.alpha-slider {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
  border: 2px solid #fff;
  transform: translateX(-3px);
}
