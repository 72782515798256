.section-header {
  font-weight: 550;
  font-size: 1.75em;
  color: #242424;
  padding: 0.75em 0 0.5em;
}
.advanced {
  color: #f0f3f6;
  font-size: 1rem;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: var(--chakra-colors-lightMode-greys-800);
  margin: 40px 0 20px;
}

/* Grid around header and items  */
.var-grid {
  margin: 5px 0;
}

/* Field Items */
.var-item {
  width: 224px;
}

/* Header of branding fields */
.var-header {
  padding: 8px 0;
  color: var(--chakra-colors-greys-500);
  font-size: 14px;
  font-family: Roboto, sans-serif;
}

.greeter-grid {
  width: 220px;
  padding: 8px;
  margin: 6px;
}

.greeter-var-titles {
  padding: 5px 0;
  color: #0a2740;
  font-size: 14px;
  font-family: Roboto, sans-serif;
}

/* Greeter Shapes  */

.greeter-shapes-item {
  width: 60px;
}

.greeter-shapes-box {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.greeter-shapes {
  border-radius: inherit;
  cursor: pointer;
  object-fit: contain;
}
/* Container of settings components */
.settings-container {
  padding: 0 6px;
  margin: 8px 0;
}

/* Settings Icons  */
.settings-icon {
  height: 18px;
  width: 18px;
  margin-right: 8px;
}

.expansionPanel {
  box-shadow:
    0px 1px 5px 0px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  margin: 2px 0;
  border-radius: 5px;
}

.expansionPanelDetailsHeader {
  font-size: 1rem;
  font-weight: bold;
  color: var(--chakra-colors-greys-500);
  padding: 6px;
}

/* Preset Icons */
.presetIconsParent {
  position: fixed;
  bottom: 30%;
  left: 240px;
  padding: 12px;
  border: 4;
  background-color: #fff;
  box-shadow: 0 2px 6px 0 #888888;
  z-index: 10000;
}

.presetIconsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
}

.settingsTitle {
  color: #0a2740;
  padding: 0 8px;
  font-weight: 550;
  font-size: 16px;
}

.settings-item {
  display: flex;
  padding: 5px 0;
}

.ui-by-img {
  height: 26px;
  margin-right: 8px;
}

/* Header Img  */

.header-image-background {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #000;
  padding: 3px;
}

.rich-header-type-paper {
  background-color: #0a2740;
  padding: 12px;
  max-width: 300px;
  margin: 8px;
}

.rich-header-item {
  margin: 5px 0;
}

.header-type-title {
  padding: 0 0 8px;
  color: #0a2740;
  font-size: 13px;
  font-family: Roboto, sans-serif;
}

.rich-header-subtitle {
  color: #0a2740;
  font-size: 13px;
  font-family: Roboto, sans-serif;
}

/* Top Bar  */

.branding-top-bar {
  background-color: #e6e8eb;
  border-bottom: 1px solid #dbdbdb;
  padding: 0 12px;
}

.show-themes-link {
  cursor: pointer;
  color: #0a2740;
  font-family:
    Open Sans,
    sans-serif;
  padding: 14px;
  display: flex;
  align-items: center;
}

.brush-icon {
  margin-right: 4px;
}

.avatar-img-box {
  /* padding: 4px; */
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
