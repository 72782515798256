.stripe-declined-grid {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  margin-top: 10px;
  border: 1px solid #a01b1b;
  border-radius: 5px;
  width: 100%;
  background-color: #f4bdbd;
}

.stripe-grid {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  padding: 16px 0;
}

.stripe-checkout {
  padding: 15px 10px;
  font-size: 14px;
  font-weight: 600;
  background-color: #fff;
  padding: 15px 10px;
  margin: 5px 0;
  border-radius: 4px;
  border: 1px solid #b8bbbd;
}

.stripe-checkout:hover {
  border: 1px solid #000;
}

.stripe-checkout-title-grid {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
}

.stripe-checkout-title-billing {
  color: #0a2740;
  font-size: 14px;
  font-weight: 600;
}

.stripe-checkout-billing-grid {
  display: flex;
  flex-direction: column;
}

.stripe-field-label {
  color: #0a2740;
  font-size: 16px;
}

.secured-by-stripe-grid {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 13px;
}

.no-card-request-access {
  margin-top: 18px;
  font-size: 13px;
}

.skip-payment {
cursor: pointer; 
padding: 8px 20px;
background-color: #fff;
color: #4B60CC;
border: none;
margin: 0 4px;
}
