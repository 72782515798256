.MuiGrid-container-89 > div > button > div {
  padding: 12px 12px 7px 12px !important;
  margin-right: 10px !important;
}

/* .google-button {
  background-color: #4285f4 !important;
  color: #fff !important;
  min-width: 300px !important;
  min-height: 45px !important;
} */

.error-alert {
  color: rgb(97, 26, 21);
  background-color: rgb(253, 236, 234);
  padding: 16px 24px;
  font-size: 14px;
}

.error-title {
  font-weight: 600;
  margin-bottom: 5px;
}

.error-text {
  padding: 5px 0;
}

.error-instruction {
  text-emphasis: italic;
  padding: 10px 0;
}

.error-icon {
  margin-right: 6px;
}

.refresh-button {
  padding: 5px 12px;
  background-color: rgb(253, 236, 234);
  border: 2px solid rgb(97, 26, 21);
  color: rgb(97, 26, 21);
  border-radius: 10px;
  margin-left: 8px;
  font-size: 13px;
}

/* Login panel  */

.login-grid {
  margin: 3.5em 4.5em 2em;
  height: 80%;
  max-width: max-content;
}

.login-title {
  text-align: center;
  font-size: 28px;
  font-weight: 500;
  color: #0a2740;
  font-family: Roboto, sans-serif;
}

.welcome-back {
  font-size: 18px;
  font-family: Open Sans, sans-serif;
  color: rgb(108, 113, 124);
}

.no-account-grid {
  display:flex;
  margin-top: 2em;
}

/* Google Button  */
.login-google-button {
  background-color: #4285f4 !important;
  color: #fff !important;
  border-radius: 4px !important;
  margin: 8px 0;
}

.login-google-button div {
  line-height: 0 !important;
  padding: 14px !important;
}

@media only screen and (min-width: 475px) {
  .login-google-button span {
    padding: 12px 42px 12px 32px !important;
  }
}

/* Company Links  */
.login-company-links-grid {
  margin-bottom: 2.5vh;
}

.login-company-links {
  margin: 0 8px;
  color: rgb(108, 113, 124);
  font-size: 13px;
}
