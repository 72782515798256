.tabs button:nth-child(1),
.tabs button:nth-child(2) {
  border-radius: 4px 4px 0 0;
}

.tabs button.inactive {
  background-color: var(--chakra-colors-lightMode-greys-800);
  color: var(--chakra-colors-lightMode-greys-1200);
}

.connect-page-outer-grid {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.container-grid-connect {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
}

.bot-details-grid {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.bot-details-fields-grid {
  margin-top: 10px;
}

.bot-region {
  color: #5c5da6;
  font-size: 14px;
  font-family: monospace;
  background-color: #e1effa;
  padding: 2px 5px;
  border-radius: 5px;
  font-weight: 600;
  margin: 12px 0 2px;
}

.permissions-dialog-content {
  color: #f0f3f6;
}

.connect-paper-title-grid {
  padding: 8px 0;
}

.connect-accordion-title {
  font-weight: 600;
  color: #0a2740;
  font-size: 16px;
  padding: 6px 8px;
}

.cx-dialog-title {
  padding: 18px 30px;
  font-size: 20px;
  color: #0a2740;
}

.cx-dialog-subtitle {
  font-size: 14px;
}

.owner-required-subtext {
  font-size: 12px;
}

.request-access-button {
  background-color: #0a2740;
  color: #dbdbdb;
  padding: 6px 16px;
  margin: 10px 0;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #0a2740;
  max-width: max-content;
}

.loading-paper {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.loading {
  font-size: 24px;
  text-align: left;
  color: #0a2740;
}

.loading-description {
  margin-top: 10px;
  color: #0a2740;
}

.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: '\2026';
  width: 0px;
}

.project-tile {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 5px;
  background-color: #dbdbdb;
  padding: 10px 15px;
  border-radius: 5px;
}

.project-id {
  font-size: 14px;
  color: #0a2740;
  font-weight: 600;
  padding: 0 10px 0 0;
  margin-right: 20px;
}

.project-text-grid {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.cx-agent-select {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  background-color: #f0f3f6;
}

.no-cx-agents-found {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

.add-integration-icon {
  margin-left: 4px;
}

.dashbot-analytics-data-title {
  color: #0a2740;
  margin-right: 4px;
}

.dashbot-analytics-data-grid {
  margin-bottom: 10px;
}

.dashbot-analytics-data-item-grid {
  padding: 4px 0;
}

.dashbot-helper-text {
  color: #238ae4;
  font-size: 13px;
  margin-top: 16px;
}

.panel-text {
  margin-bottom: 12px;
}

.connect-docs-link {
  color: #0a2740;
  text-align: center;
  font-size: 1em;
  display: flex;
  justify-content: flex-end;
}

.connect-fields-grid-title {
  color: #0a2740;
  font-family:
    Open Sans,
    sans-serif;
  font-size: 14px;
  font-weight: bold;
  margin: 12px 0 10px;
}

.connect-accordion-subtitle {
  color: #0a2740;
  font-family:
    Open Sans,
    sans-serif;
}

.connect-divider {
  width: 20px;
  height: 1px;
  background-color: #cbcbcb;
  margin: 20px 6px 18px;
}

.connect-fields-grid {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.connect-fields-grid-item {
  display: flex;
  flex-direction: column;
  padding: 0 6px;
  margin-right: 30px;
}

.connect-fields-grid-item-adjacent {
  display: flex;
  flex-direction: column;
  max-width: fit-content;
  justify-content: space-between;
  margin: 0 6px;
}

.add-context-button {
  background-color: #0a2740;
  color: #dbdbdb;
  cursor: pointer;
  border-radius: 5px;
  font-size: 20px;
  border: 1px solid #0a2740;
  max-width: max-content;
}

.add-context-button-disabled {
  background-color: #0a2740;
  color: #dbdbdb;
  opacity: 0.8;
  font-size: 20px;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #0a2740;
  max-width: max-content;
}

.edit-button {
  display: flex;
  color: #0a2740;
  cursor: pointer;
  border-radius: 5px;
  border: none;
  padding: 0;
  background-color: transparent;
  margin: 0 8px;
}

.ending-in {
  margin-bottom: 5px;
}

.connect-row-item {
  padding: 2px 0;
}

.live-chat-settings-grid {
  margin: 0 6px;
}

.connect-secondary-button {
  background-color: transparent;
  color: #848482;
  cursor: pointer;
}

.text-area-snippet {
  color: var(--chakra-colors-lightMode-greys-1200);
  width: 100%;
  border: 2px solid var(--chakra-colors-lightMode-greys-800);
  border-radius: 0 0 5px 5px;
  padding: 10px;
}

#textarea-snippet:focus-visible {
  border-color: var(--chakra-colors-blue-1000);
}

.blur {
  filter: blur(2px);
  -webkit-filter: blur(2px);
  opacity: 0.8;
  cursor: pointer;
}

.onhold-banner {
  height: 155px;
  background-color: #b33a3a;
  padding: 40px 40px 40px 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.onhold-banner-title {
  color: #fff;
  font-size: 24px;
  font-weight: 500;
}

.onhold-banner-subtitle {
  color: #fff;
  font-size: 16px;
}

.onhold-plan-button {
  padding: 8px 20px;
  letter-spacing: 1px;
  border: 1px solid #fff;
  color: #fff;
  max-width: fit-content;
  border-radius: 4px;
}

.allowed-domain-bubble-grid .allowed-domain-bubble {
  background-color: #4b60cc;
  color: #fff;
  padding: 5px 12px;
  border-radius: 20px;
  margin: 4px;
  text-transform: lowercase;
  font-size: 12px;
}

.allowed-domain-bubble-grid .allowed-domain-bubble:hover {
  opacity: 0.9;
  background-color: #4b60cc;
  color: #fff;
}

.allowed-domain-bubble-grid .exception-domain-bubble {
  background-color: #848482;
  color: #fff;
  padding: 5px 12px;
  border-radius: 20px;
  font-size: 12px;
  margin: 4px;
  text-transform: lowercase;
}

.allowed-domain-bubble-grid .exception-domain-bubble:hover {
  opacity: 0.9;
  background-color: #848482;
  color: #fff;
}

.domain-text {
  color: #000;
  font-size: 14px;
  margin-top: 10px;
}

.dialog-text {
  color: #000;
  font-size: 14px;
  margin-top: 10px;
}

.connect-card {
  padding: 32px;
  flex: 1;
  min-width: 450px;
}

/* Hack to allow the dropdown in Agent One's accordion to overflow past the accordion.
This might be fixed by Chakra UI v3 */
.agent-one-accordion-item .chakra-collapse {
  overflow: visible !important;
}
